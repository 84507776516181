<template lang="pug">
    div
        v-snackbar(
            :top="true"
            :right="true"
            :color="alertColor"
            v-model="alert")
                | {{ alertText }}
                v-btn(flat dark @click.native="alert = false") {{$t('cerrar')}}
        v-dialog(v-model="modal" max-width="600")
            v-form
                v-card
                    v-card-title {{ $t('Asignar red a este subadministrador') }}
                    v-card-text
                        template(v-if="preload")
                            h3 {{$t('Cargando')}}...
                        template(v-else)
                            v-layout(row wrap)
                                p: i {{ $t('Busca la red activa para asociar a este subadministrador') }}
                                v-flex(xs12)
                                    v-select.mb-3(
                                        v-model="model.networkid"
                                        :items='networks'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Red")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                    )
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click.native="modal = false") {{ $t('Cancelar') }}
                            v-btn(color="primary" large @click.native="save" :loading="loader") {{ $t('Guardar') }}
</template>
<script>

    import fn from 'mixins/fn'
    export default {
        mixins: [fn],
        data() {
            return {
                modal: false,
                modelSchema: {
                    userid   : '',
                    networkid: ''
                },
                model        : {},
                alert        : false,
                alertText    : '',
                alertColor   : '',
                preload      : true,
                loader       : false,
                networks     : [],
            }
        },
        created(){
            this.fetchNetwork()
        },
        methods: {

            fetchNetwork(){

                this.networks = []
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/network', {
                            params: {
                                list: 1
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.networks = data.data
                            }
                            resolve()

                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            async open(userid){

                this.model = this._.cloneDeep(this.modelSchema)
                this.loader = false

                this.modal = true
                this.model.userid = userid

                this.preload = true
                await this.fetchNetwork()
                this.preload = false
            },
            save(){

                if(this.model.networkid == '' || !Object.keys(this.model.networkid).length){

                    this.alert      = true
                    this.alertColor = 'error'
                    this.alertText  = this.$t('Seleccione una red')
                    return
                }

                this.loader = true
                this.$api(this, (xhr) => {
                    xhr.post('/billing/subscriber/assign', this.$qs.stringify({
                        userid    : this.model.userid,
                        networkid : this.model.networkid
                    })).then((r) => {

                        let data = r.data
                        this.loader = false
                        this.alert  = true
                            
                        if(data.response){

                            this.alertColor = 'success'
                            this.alertText  = this.$t(data.message)
                            
                            this.modal = false
                            this.$emit('success')
                        }else{
                            this.alertColor = 'error'
                            this.alertText  = this.$t(data.message)
                        }
                    }).catch(() => {
                        this.loader = false
                        this.alert  = true
                        this.alertColor = 'error'
                        this.alertText  = this.$t('Ha ocurrido un error')
                    })
                })
            }
        }
    }

</script>