<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-snackbar(
                    :timeout="3000",
                    :top="true"
                    :right="true"
                    :color="subadminColor"
                    v-model="subadminMsj")
                    | {{ subadminText }}
                    v-btn(flat dark @click="subadminMsj = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Subadministradores')}}
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-data-table(
                            :headers='headers'
                            :items='subadmin'
                            :pagination.sync='pagination'
                            :rows-per-page-items="$root.rowsPageItems"
                            :rows-per-page-text="$root.rowsPageText" 
                            item-key='uuid'
                            :total-items="total"
                            :expand="expand"
                            :loading="loading"
                        )   
                            template(slot="no-results")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot="no-data")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot='headers' slot-scope='props')
                                tr
                                    th(v-for='header in props.headers' :key='header.text' :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" @click='changeSort(header.value)' :align="header.align")
                                        span.subheading {{ $t(header.text) }}
                                        v-icon(small) arrow_upward
                            template(slot='items' slot-scope='props')
                                tr(@click="props.expanded = !props.expanded")
                                    td {{ props.item.names }}
                                    td {{ props.item.email }}
                                    td
                                        a(target="_blank" :href="props.item.whatsapp") {{ props.item.phone }}
                                    td {{ props.item.country }}
                                    td {{ props.item.city }}
                                    td {{ $t(props.item.status == 1 ? 'Activo' : 'Inactivo') }}
                                    td
                                        | {{ $t('Fecha')}}: {{ props.item.datecreated | moment('YYYY-MM-DD') }} 
                                        br
                                        | {{$t('Hora')}}: {{ props.item.datecreated | moment('HH:mm') }}
                                    td.text-xs-right
                                        v-tooltip(top)
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text(@click.stop="openAv(props.item.uuid, props.item)") map
                                            span {{$t('Disponibilidad') }}
                                        v-tooltip(top v-if="props.item.networks.length")
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text group
                                            span {{$t('Redes')}}
                                        v-tooltip(top v-if="props.item.status == 1")
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text(@click.stop="state(props.item.uuid, 0)") clear
                                            span {{$t('Desactivar')}}
                                        v-tooltip(top v-if="props.item.status == 0")
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text(@click.stop="state(props.item.uuid, 1)") done
                                            span {{$t('Activar')}}
                                        v-tooltip(top v-if="profile.type == 0")
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text(@click.stop="$refs.reset.open(props.item.uuid)") vpn_key
                                            span {{$t('Cambiar contraseña')}}
                                        v-tooltip(top v-if="profile.type == 0")
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text(@click.stop="$refs.assoc.open(props.item.uuid)") add
                                            span {{$t('Asociar red')}}
                            template(slot='expand' slot-scope='props')
                                v-list
                                    v-list-tile(v-for="(n, i) in props.item.networks" :key="'n'+i")
                                        v-list-tile-content
                                            small {{$t('Red')}}: {{ n.name }}
                                        v-list-tile-action
                                            v-icon.cur-p.primary--text(@click.stop="$refs.disassoc.open({userid:props.item.uuid,networkid:n.uuid})") delete
                            template(slot='pageText' slot-scope='props')
                                | {{$t('Filas')}} {{ props.pageStart }} - {{ props.pageStop }} {{$t('de')}} {{ props.itemsLength }}
                    v-dialog(v-model="avModal" max-width="800")
                        v-form
                            v-card
                                v-card-text
                                    template(v-if="preload")
                                        h3 {{$t('Cargando')}}...
                                    v-layout(row wrap v-else)
                                        template(v-if="node.length")
                                            v-flex.mt-3(xs12)
                                                h3 {{$t('Disponibilidad regional para crear redes')}}
                                            v-flex(xs12)
                                                v-btn.primary(small @click="selectAll") {{ $t('Seleccionar todos') }}
                                                v-btn.primary(small @click="unselectAll") {{ $t('Deseleccionar todos') }}
                                                liquor-tree(
                                                    ref="treeNode"
                                                    :data="node"
                                                    :options="tree"
                                                )
                                v-card-actions
                                    .btns.btns-right
                                        v-btn(color="secondary", small, @click.native="avModal = false") {{$t('Cancelar')}}
                                        v-btn(:loading="loadingBtn" color="primary" large @click.native="saveSubadmin") {{$t('Guardar')}}
        reset-cmp(ref="reset")
        assoc-network(ref="assoc" @success="fetchSubadmin")
        confirm(ref="disassoc" @action="disassoc" :title="$t('Desasociar red')" :message="$t('¿Estás seguro de desasociar esta red a este subadministrador?')")
</template>

<script>

    import auth from 'mixins/auth'
    import _ from 'lodash/debounce'
    import _m from 'lodash/merge'
    import AssocNetwork from 'components/subadmin/AssocNetwork'
    
    export default {
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Subadministradores')
            }
        },
        components: {
            AssocNetwork
        },
        data() {
            return {
                // Loader
                preload        : false,
                pagination     : {
                    page       : 1,
                    rowsPerPage: 20
                },
                total          : 0,
                loading        : false,
                cancelFilter   : null,
                isFilter       : false,

                expand: false,
                drawerRight: false,
                modalDateStart: false,
                modalDateEnd: false,
                filter: {},
                filterSchema: {
                    query    : '',
                    status   : []
                },
                subadmin      : [],
                loadingBtn: false,
                headers: [
                    {
                        text: 'Nombre',
                        align: 'left',
                        value: 'names'
                    },
                    {
                        text: 'Correo electrónico',
                        align: 'left',
                        value: 'email'
                    },
                    {
                        text: 'WhatsApp',
                        align: 'left',
                        value: 'phone'
                    },
                    {
                        text: 'País',
                        align: 'left',
                        value: 'country'
                    },
                    {
                        text: 'Ciudad',
                        align: 'left',
                        value: 'city'
                    },
                    {
                        text: 'Estado',
                        align: 'left',
                        value: 'status'
                    },
                    {
                        text: 'Fecha de registro',
                        align: 'left',
                        value: 'datecreated'
                    },
                    {
                        text: 'Opciones',
                        align: 'right',
                        value: 'options',
                        sortable: false
                    }
                ],
                subadminMsj  : false,
                subadminColor: '',
                subadminText : '',
                avModal: false,
                node: [],
                tree: {
                    multiple: true,
                    checkbox: true,
                    checkOnSelect:true,
                    parentSelect: true,
                    fetchData: (node) => {
                        return new Promise((resolve) => {

                            let url  = '/state'
                            let type = 1
                            let params = {
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }

                            if(node.data.type == 1){
                                params.countryid = node.data.uuid
                                type = 2
                            }

                            if(node.data.type == 2){
                                url  = '/city'
                                params.stateid = node.data.uuid
                                type = 3
                            }

                            if(node.data.type == 3){
                                url  = '/zone'
                                params.cityid = node.data.uuid
                                params.parent = 0
                                type = 4
                            }

                            if(node.data.type == 4){
                                url  = '/zone'
                                params.cityid = node.data.cityid
                                params.parent = node.data.uuid
                                type = 4
                            }

                            this.$api(this, (xhr) => {
                                xhr.get(url, {params}).then((r) => {

                                    let data = r.data
                                    let node = data.data.length ? data.data : []

                                    node = node.map((node) => {
                                        node.type = type                                        
                                        return {
                                            text: node.name,
                                            data: node,
                                            isBatch: true
                                        }
                                    })

                                    resolve(node.map(this.checkNode))
                                }).catch(() => {
                                    resolve([])
                                })
                            })
                        })
                    }
                },
                subadminid: '',
                context: {}
            }
        },
        watch: {
            filter: {
                handler(){

                    this.preload  = true
                    this.total    = 0
                    this.subadmin = []

                    this.fetchSubadmin(true)
                },
                deep: true
            },
            pagination: {
                handler () {
                    if(!this.isFilter){
                        this.fetchSubadmin()
                    }
                },
                deep: true
            }
        },
        created(){
            this.filter = _m({}, this.filterSchema)
        },
        methods: {

            fetchSubadmin: _(function(isFilter = false){

                this.isFilter = isFilter

                if(this.cancelFilter != null){
                    this.cancelFilter.cancel()
                }

                let CancelToken   = this.$axios.CancelToken
                this.cancelFilter = CancelToken.source()
                
                this.loading = true
                this.$Progress.start()

                let url    = '/billing/subscriber'
                let filter = this.filter
                let params = {
                    limit  : this.pagination.rowsPerPage,
                    offset : (this.pagination.page - 1) * this.pagination.rowsPerPage,
                    filter : JSON.stringify(filter),
                    sort   : this.pagination.sortBy,
                    order  : (this.pagination.descending ? 'DESC' : 'ASC'),
                }

                this.$api(this, (xhr) => {
                    xhr.get(url, { 
                        cancelToken: this.cancelFilter.token,
                        params: params
                    }).then((r) => {

                        let data = r.data
                        this.preload = false
                        this.loading = false
                        this.$Progress.finish()

                        if(data.response){
                            this.subadmin = data.data
                            this.total = data.total
                        }else{
                            this.subadmin = []
                        }

                        this.$nextTick(() => {
                            this.isFilter = false
                        })

                    }).catch(() => {
                        
                        this.preload   = false
                        this.loading   = false
                        this.isFilter  = false
                        this.$Progress.finish()
                        
                        this.catchError('user')
                    })
                })

            }, 500),

            changeSort (column) {
              
                if (this.pagination.sortBy === column) {
                    this.pagination.descending = !this.pagination.descending
                } else {
                    this.pagination.sortBy = column
                    this.pagination.descending = false
                }
            },

            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            },

            state(userid, state){

                if(!this.isadmin){
                    return
                }

                let post = {
                    userid,
                    state
                }

                this.$api(this, (xhr) => {
                    xhr.post('/billing/subscriber/state', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.subadminMsj = true

                        if(data.response){
                            this.subadminColor = 'success'
                            this.subadminText = data.message
                            this.fetchSubadmin()
                        }else{
                            this.subadminColor = 'error'
                            this.subadminText = data.message
                        }
                    }).catch(() => {})
                })
            },

            openAv(uuid, context){

                this.preload = true
                this.loadingBtn = false

                this.node = []
                this.fetchCountry()
                
                this.$nextTick(() => {
                    this.avModal = true
                    this.preload = false
                    this.subadminid = uuid
                    this.context = context
                })
            },

            fetchCountry(){

                let params = {
                    filter: JSON.stringify({
                        status: 1
                    })
                }

                this.$api(this, (xhr) => {
                    xhr.get('/country', {params}).then((r) => {
                        let data = r.data
                        if(data.response){

                            let node = data.data
                            node = node.map((node) => {

                                node.type = 1
                                return {
                                    text: node.name,
                                    data: node,
                                    isBatch: true
                                }
                            })
                            this.node = node.map(this.checkNode)
                        }
                    }).catch(() => {})
                })
            },

            checkNode(node){

                let found = this.context.availability.filter((ab) => {
                    return node.data.type == ab.type && node.data.uuid == ab.typeid 
                })

                //let found = [];
                let checked = found.filter((node) => node.partial == 0) 
                let partial = found.filter((node) => node.partial == 1) 
                
                if(partial.length){
                    node.state = { indeterminate: true }
                }

                if(checked.length){
                    node.state = { checked: true }
                }

                return node
            },

            saveSubadmin(){

                let nodes = this.$refs.treeNode.findAll({})
                nodes = nodes == null ? [] : nodes
                nodes = nodes.filter((node) => {
                    return node.indeterminate() || node.checked()
                })

                nodes = nodes.map((node) => {
                    return {
                        partial: node.indeterminate() ? 1 : 0,
                        type   : node.data.type,
                        typeid : node.data.uuid
                    }
                })

                let post = {
                    subadminid: this.subadminid, 
                    availability: JSON.stringify(nodes)
                }

                this.loadingBtn = true
                this.$api(this, (xhr) => {
                    xhr.post('/billing/subscriber/availability', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.loadingBtn = false
                        this.subadminMsj = true

                        if(data.response){
                            this.subadminColor = 'success'
                            this.subadminText = data.message
                            this.avModal = false
                            this.fetchSubadmin()
                        }else{
                            this.subadminColor = 'error'
                            this.subadminText = data.message
                        }
                    }).catch(() => {})
                })
            },

            selectAll(){
                let tree = this.$refs.treeNode.findAll({})
                tree.check(true)
            },

            unselectAll(){
                let tree = this.$refs.treeNode.findAll({})
                tree.uncheck(true)
            },
            disassoc({userid, networkid}){

                this.$refs.disassoc.$emit('playLoader')
                this.$api(this, (xhr) => {
                    xhr.delete('/billing/subscriber/disassoc', {
                        params: {
                            userid,
                            networkid
                        }
                    }).then((r) => {
                        
                        this.$refs.disassoc.$emit('stopLoader')
                        this.subadminMsj = true

                        let data = r.data

                        if(data.response){
                            this.subadminColor = 'success'
                            this.subadminText = data.message
                            this.$refs.disassoc.close()
                            this.fetchSubadmin()
                        }else{
                            this.subadminColor = 'error'
                            this.subadminText = data.message
                        }
                    }).catch(() => {})
                })
            },
        }
    }
</script>